import { classConstants } from "../constants/index";

const initialSettings = {
  class: [],
  classes: [],
  classesList: [],
  classLoading: false,
  msg: null,
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action) {
  switch (action.type) {
    case classConstants.REQUEST_CLASS_ADD:
      return {
        ...state,
        classLoading: true,
      };

    case classConstants.RESPONSE_CLASS_ADD:
      console.log("action is===", action);
      return {
        ...state,
        classLoading: false,
        classes: [...state.classes, ...action.data.data.cls], //'create class' response stored in classes
        msg: action.data.data.msg,
      };

    case classConstants.FAILURE_CLASS_ADD:
      return {
        ...state,
        classLoading: false,
        msg: action.data.data.msg,
      };
      
    case classConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    case classConstants.REMOVE_CLASS_LIST:
      return {
        ...state,
        classesList: [],
        classes: [],
      };
    // Fetch class Reducers
    case classConstants.REQUEST_CLASS_FETCH:
      return {
        ...state,
        classLoading: true,
      };

    case classConstants.RESPONSE_CLASS_FETCH:
      console.log("Action.data", action.data);
      const classList = action.data && action.data.data ? action.data.data : [];
      const counts = action.data && action.data.counts ? action.data.counts : [];
      console.log("classList", classList);
      return {
        ...state,
        classLoading: false,
        classesList: classList,
        count: counts,
      };

    // Reducer for update Class
    case classConstants.REQUEST_CLASS_UPDATE:
      return {
        ...state,
        classLoading: true,
      };
    case classConstants.RESPONSE_CLASS_UPDATE:
      const updateClassMeta = action.data;
      console.log("Action data", updateClassMeta);

      console.log("Update Class id", action.data.data.cls._id);
      let beforeEditClassList = state.classesList;
      let afterEditClassList: any[] = [];

      beforeEditClassList.map((cls) => {
        console.log("id", action.data.data.cls._id);
        //@ts-ignore
        if (cls._id !== action.data.data.cls._id) {
          afterEditClassList.push(cls);
        } else {
          afterEditClassList.push(action.data.data.cls);
        }
        return console.log(cls);
      });

      return {
        ...state,
        classLoading: false,
        classesList: afterEditClassList,
        class: updateClassMeta,
        msg: action.data.data.msg,
      };

    // Reducer for delete Class
    case classConstants.REQUEST_CLASS_DELETE:
      return {
        ...state,
        classLoading: true,
      };
    case classConstants.RESPONSE_CLASS_DELETE:
      const deleteClassMeta = action.data;
      console.log("Action data", deleteClassMeta);
      console.log("Action", action);
      console.log("Delete Class id", action.data.data.data.classes._id);
      let beforeDeleteClassList = state.classesList; //classes list includes all class entries
      let beforeDeleteClasses = state.classes;
      //@ts-ignore
      console.log("classlist", beforeDeleteClassList);
      let afterDeleteClassList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.classes._id
      ) {
        //@ts-ignore
        beforeDeleteClassList = state.classesList.filter((classes) => classes._id!== action.data.data.data.classes._id)
        //@ts-ignore
        beforeDeleteClasses = state.classes.filter((classes) => classes._id!== action.data.data.data.classes._id)
      }

      return {
        ...state,
        classLoading: false,
        classes: beforeDeleteClasses,
        classesList: afterDeleteClassList,
      };

    case classConstants.RESPONSE_CLASS_DELETE_ADD:
      console.log("Action data", deleteClassMeta);
      console.log("Action", action);
      console.log("Delete Class id", action.data.data.data.classes._id);
      let beforeDeleteClassList2 = state.classes; //classes includes only recent 'create class' entries
      //@ts-ignore
      console.log("classlist2", beforeDeleteClassList2);
      let afterDeleteClassList2: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.classes._id
      ) {
        if (beforeDeleteClassList2.length) {
          beforeDeleteClassList2.map((cls: any[]) => {
            console.log("class", cls);
            //@ts-ignore
            if (cls._id !== action.data.data.data.classes._id) {
              afterDeleteClassList2.push(cls);
            }
            return console.log(cls);
          });
        }
      }

      return {
        ...state,
        classLoading: false,
        classes: afterDeleteClassList2,
      };

    default:
      return state;
  }
}
