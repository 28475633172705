import { boardConstants } from "../constants/index";

const initialSettings = {
  board: [],
  boards: [],
  boardsList: [],
  boardsLoading: false,
  msg: null,
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action) {
  switch (action.type) {
    case boardConstants.REQUEST_BOARD_ADD:
      return {
        ...state,
        boardsLoading: true,
      };

    case boardConstants.RESPONSE_BOARD_ADD:
      console.log("action is===", action);
      console.log("Action.data", action.data);
      console.log("Action.data1", action.data.data.msg);
      const board2 =
        action.data && action.data.data && action.data.data.board ? action.data.data.board : [];
      console.log("board2", board2);
      return {
        ...state,
        boardsLoading: false,
        boards: [...state.boards,...action.data.data.board],
        board: board2,
        msg: action.data.data.msg,
      };

    case boardConstants.FAILURE_BOARD_ADD:
      return {
        ...state,
        boardsLoading: false,
        msg: action.data.data.msg,
      };

    case boardConstants.REMOVE_BOARD_LIST:
      return {
        ...state,
        boardsList: [],
        boards: [],
      };

    case boardConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    // Fetch board Reducers
    case boardConstants.REQUEST_BOARD_FETCH:
      return {
        ...state,
        boardsLoading: true,
      };

    case boardConstants.RESPONSE_BOARD_FETCH:
      console.log("Action Data", action.data);
      const boardList =
        action.data && action.data.data && action.data.data.board && action.data.data.board.length
          ? action.data.data.board
          : [];
      const counts = action.data && action.data.counts ? action.data.counts : [];
      console.log("boardList", boardList);
      return {
        ...state,
        boardsLoading: false,
        boardsList: boardList,
        count: counts,
      };

    // Reducer for update Board
    case boardConstants.REQUEST_BOARD_UPDATE:
      return {
        ...state,
        boardsLoading: true,
      };
    case boardConstants.RESPONSE_BOARD_UPDATE:
      const updateBoardMeta = action.data && action.data.data ? action.data.data : [];
      console.log("Action data>", updateBoardMeta);

      console.log("Update Board id", action.data.data.board._id);
      let beforeEditBoardList = state.boardsList;
      let afterEditBoardList: any[] = [];

      beforeEditBoardList.map((board) => {
        console.log("id", action.data.data.board._id);
        //@ts-ignore
        if (board._id !== action.data.data.board._id) {
          afterEditBoardList.push(board);
        } else {
          afterEditBoardList.push(action.data.data.board);
        }
        return console.log(board);
      });

      return {
        ...state,
        boardsLoading: false,
        boardsList: afterEditBoardList,
        board: updateBoardMeta,
        msg: action.data.data.msg,
      };

    // Reducer for delete Board
    case boardConstants.REQUEST_BOARD_DELETE:
      return {
        ...state,
        boardsLoading: true,
      };
    case boardConstants.RESPONSE_BOARD_DELETE:
      const deleteBoardMeta = action.data;
      console.log("Action data", deleteBoardMeta);
      console.log("Action", action);
      console.log("Delete Board id", action.data.data.data.board._id);
      let beforeDeleteBoardList = state.boardsList;
      let beforeDeleteBoards = state.boards;
      //@ts-ignore
      console.log("boardlist", beforeDeleteBoardList);
      let afterDeleteBoardList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.board._id
      ) {
        //@ts-ignore
        beforeDeleteBoardList = state.boardsList.filter((board) => board._id!== action.data.data.data.board._id)
        //@ts-ignore
        beforeDeleteBoards = state.boards.filter((board) => board._id!== action.data.data.data.board._id)
        // if (beforeDeleteBoardList.length) {
        //   beforeDeleteBoardList.map((bo: any[]) => {
        //     console.log("board", bo);
        //     //@ts-ignore
        //     if (bo._id !== action.data.data.data.board._id) {
        //       afterDeleteBoardList.push(bo);
        //     }
        //     return console.log(bo);
        //   });
        // }
      }

      return {
        ...state,
        boardsLoading: false,
        boards: beforeDeleteBoards,
        boardsList: afterDeleteBoardList,
      };

    default:
      return state;
  }
}
