import {adminConstants} from "../constants/index";

const initialSettings = {
    config: [],
    configs: [],
    configsList: [],
    configLoading: false,
    msg: null,
}

// @ts-ignore
export default function reducer(state = initialSettings, action) {
    switch (action.type) {
  
      case adminConstants.REQUEST_CONFIG_ADD:
        return {
          ...state,
          configLoading: true,
        }
  
      case adminConstants.RESPONSE_CONFIG_ADD:
        
        console.log("action is===",action);
        return {
          ...state,
          configLoading: false,
          configs: action.data.data.appconfig,
          msg: action.data.data.msg
        }
  
      case adminConstants.CLEAR_MSG:
        return {
            ...state,
            msg: "",
        }

        default:
            return state;
        }
      }