import {bookConstants} from "../constants/index";

const initialSettings = {
  coursebook: [],
  coursebooksList: [],
  generalBook: [],
  generalBooksList: [],
  booksLoading: false,
}

// @ts-ignore
export default function reducer(state = initialSettings, action) {
  switch (action.type) {

    case bookConstants.REQUEST_COURSEBOOK_ADD:
      return {
        ...state,
        booksLoading: true,
      }

    case bookConstants.RESPONSE_COURSEBOOK_ADD:
      console.log("Action.data", action.data)
      const coursebook2 = action.data && action.data.data ? action.data.data : null
      console.log("coursebook", coursebook2)
      let newCourseList = state.coursebooksList
      // @ts-ignore
      newCourseList.push(newCourseList)
      // console.log("NewList Coursebook", newCourseList)

      return {
        ...state,
        booksLoading: false,
        coursebook: coursebook2,
        coursebooksList: newCourseList
      }

    // Fetch coursebook Reducers
    case bookConstants.REQUEST_COURSEBOOK_FETCH:
      return {
        ...state,
        booksLoading: true,
      }

    case bookConstants.RESPONSE_COURSEBOOK_FETCH:
      console.log("Action.data", action.data)
      const CoursebookList = action.data && action.data.data ? action.data.data : []
      console.log("CoursebookList", CoursebookList)
      return {
        ...state,
        booksLoading: false,
        coursebooksList: CoursebookList
      }

    case bookConstants.REQUEST_GENERALBOOK_ADD:
      return {
        ...state,
        booksLoading: true,
      }

    case bookConstants.RESPONSE_GENERALBOOK_ADD:
      console.log("Action.data", action.data)
      const generalBook = action.data && action.data.data ? action.data.data : null
      console.log("generalBook", generalBook)
      let newGeneralBookList = state.generalBooksList
      // @ts-ignore
      newGeneralBookList.push(generalBook)
      // console.log("NewList Coursebook", newCourseList)

      return {
        ...state,
        booksLoading: false,
        coursebook: generalBook,
        coursebooksList: newGeneralBookList
      }

    // Fetch coursebook Reducers
    case bookConstants.REQUEST_GENERAL_BOOK_FETCH:
      return {
        ...state,
        booksLoading: true,
      }

    case bookConstants.RESPONSE_GENERAL_BOOK_FETCH:
      console.log("Action.data", action.data)
      const generalBooksList = action.data && action.data.data ? action.data.data : []
      console.log("generalBooksList", generalBooksList)
      return {
        ...state,
        booksLoading: false,
        generalBooksList: generalBooksList
      }

    // Reducer for delete Coursebook
    case bookConstants.REQUEST_COURSEBOOK_DELETE:
      return {
                ...state,
                booksLoading: true,
            }
    case bookConstants.RESPONSE_COURSEBOOK_DELETE:
        const deleteCoursebookMeta = action.data
        console.log("Action data",deleteCoursebookMeta)

        console.log("Delete Coursebook id", action.data.data.data._id)
        let beforeDeleteCoursebookList = state.coursebooksList
        let afterDeleteCoursebookList: any[] = [];

        beforeDeleteCoursebookList.map(cat => {
          console.log("id",action.data.data.data._id);
          //@ts-ignore
            if(cat._id !== action.data.data.data._id){
               return afterDeleteCoursebookList.push(cat)
            }
            return console.log(cat);
        })

      return {
          ...state,
          booksLoading: false,
          coursebooksList: afterDeleteCoursebookList,
      }

     // Reducer for delete Generalbook
    case bookConstants.REQUEST_GENERALBOOK_DELETE:
      return {
                ...state,
                booksLoading: true,
            }
    case bookConstants.RESPONSE_GENERALBOOK_DELETE:
        const deleteGeneralbookMeta = action.data
        console.log("Action data",deleteGeneralbookMeta)

        console.log("Delete Generalbook id", action.data.data.data._id)
        let beforeDeleteGeneralbookList = state.generalBooksList
        let afterDeleteGeneralbookList: any[] = [];

        beforeDeleteGeneralbookList.map(cat=>{
          console.log("id",action.data.data.data._id);
          //@ts-ignore
            if(cat._id !== action.data.data.data._id){
              afterDeleteGeneralbookList.push(cat)
            }
            return console.log(cat);
        })

      return {
          ...state,
          booksLoading: false,
          generalBooksList: afterDeleteGeneralbookList,
      }

    default:
      return state;
  }
}

