import { contentConstants } from "../constants/index";

const initialSettings = {
  content: [],
  contents: [],
  contentsList: [],
  subjectContentsList: [],
  // generalContent: [],
  // generalContentsList: [],
  contentLoading: false,
  msg: null,
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action) {
  switch (action.type) {
    case contentConstants.REQUEST_COURSECONTENT_ADD:
      return {
        ...state,
        contentLoading: true,
      };

    case contentConstants.RESPONSE_COURSECONTENT_ADD:
      console.log("action is===", action);

      return {
        ...state,
        contentLoading: false,
        contents: [action.data.data.content],
        msg: action.data.data.msg,
      };

    case contentConstants.REQUEST_MASTERCONTENT_ADD:
      return {
        ...state,
        contentLoading: true,
      };

    case contentConstants.RESPONSE_MASTERCONTENT_ADD:
      console.log("action is===", action);

      return {
        ...state,
        contentLoading: false,
        contents: [...state.contents, action.data.data.content],
        msg: action.data.data.msg,
      };
    // console.log("Action.data", action.data)
    // const content2 = action.data && action.data.data ? action.data.data : null
    // console.log("content", content2)
    // let newContentList = state.contentsList
    // // @ts-ignore
    // newContentList.push(newContentList)
    // // console.log("NewList", newcontentlistList)

    // return {
    //     ...state,
    //     contentLoading: false,
    //     content: content2,
    //     contentsList: newContentList,
    //     msg: action.data.data.msg
    // }

    case contentConstants.FAILURE_MASTERCONTENT_ADD:
      return {
        ...state,
        contentLoading: false,
        msg: action.data.data.msg,
      };

    case contentConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    case contentConstants.REMOVE_COURSE_LIST:
      return {
        ...state,
        contentsList: [],
        contents: [],
      };

    // Fetch contentlist Reducers
    case contentConstants.REQUEST_COURSECONTENT_FETCH:
      return {
        ...state,
        contentLoading: true,
      };

    case contentConstants.RESPONSE_COURSECONTENT_FETCH:
      console.log("Action.data", action.data);
      const contentList =
        action.data && action.data.data && action.data.data.data ? action.data.data.data : [];
      const counts = action.data && action.data.counts ? action.data.counts : [];
      console.log("contentList", contentList);
      return {
        ...state,
        contentLoading: false,
        contentsList: contentList,
        count: counts,
      };

    //fetch subject content reducer
    case contentConstants.REQUEST_SUBJECT_COURSECONTENT_FETCH:
      return {
        ...state,
        contentLoading: true,
      };

    case contentConstants.RESPONSE_SUBJECT_COURSECONTENT_FETCH:
      console.log("Action.data==>", action.data);
      const contentSubjectList =
        action.data && action.data.data && action.data.data.data ? action.data.data.data : [];
      const subjectListCounts = action.data && action.data.counts ? action.data.counts : [];
      console.log("contentSubjectList", contentSubjectList);
      return {
        ...state,
        contentLoading: false,
        subjectContentsList: contentSubjectList,
        count: subjectListCounts,
      };

    case contentConstants.REQUEST_GENERALCONTENT_ADD:
      return {
        ...state,
        contentLoading: true,
      };

    case contentConstants.RESPONSE_GENERALCONTENT_ADD:
      console.log("Action.data", action.data);
      const generalBook = action.data && action.data.data ? action.data.data : null;
      console.log("generalBook", generalBook);
      let newGeneralBookList = state.contentsList;
      // @ts-ignore
      newGeneralBookList.push(generalBook);
      // console.log("NewList Coursebook", newCourseList)

      return {
        ...state,
        contentLoading: false,
        content: generalBook,
        contentsList: newGeneralBookList,
      };

    // Fetch generalbook Reducers
    // case contentConstants.REQUEST_GENERALCONTENT_FETCH:
    //     return {
    //         ...state,
    //         contentLoading: true,
    //     }

    // case contentConstants.RESPONSE_GENERALCONTENT_FETCH:
    //     console.log("Action.data", action.data)
    //     const generalBooksList = action.data && action.data.data ? action.data.data : []
    //     console.log("generalBooksList", generalBooksList)
    //     return {
    //         ...state,
    //         contentLoading: false,
    //         generalContentsList: generalBooksList
    //     }

    // Reducer for update Content
    case contentConstants.REQUEST_CONTENT_UPDATE:
      return {
        ...state,
        contentLoading: true,
      };
    case contentConstants.RESPONSE_CONTENT_UPDATE:
      const updateContentMeta = action.data;
      console.log("Action data", updateContentMeta);

      console.log("Update Content id", action.data.data.content._id);
      let beforeEditContentList = state.contentsList;
      let afterEditContentList: any[] = [];

      beforeEditContentList.map((content) => {
        console.log("id", action.data.data.content._id);
        //@ts-ignore
        if (content._id !== action.data.data.content._id) {
          afterEditContentList.push(content);
        } else {
          afterEditContentList.push(action.data.data.content);
        }
        return console.log(content);
      });

      return {
        ...state,
        contentLoading: false,
        contentsList: afterEditContentList,
        content: updateContentMeta,
        msg: action.data.data.msg,
      };

    // Reducer for delete Content
    case contentConstants.REQUEST_CONTENT_DELETE:
      return {
        ...state,
        contentLoading: true,
      };
    case contentConstants.RESPONSE_CONTENT_DELETE:
      const deleteContentMeta = action.data;
      console.log("Action data", deleteContentMeta);
      console.log("Action", action);
      console.log("Delete Content id", action.data.data.data.content._id);
      let beforeDeleteContentList = state.contentsList;
      let beforeDeleteContents = state.contents;
      //@ts-ignore
      console.log("contentlist", beforeDeleteContentList);
      let afterDeleteContentList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.content._id
      ) {
        //@ts-ignore
        beforeDeleteContentList = state.contentsList.filter((content) => content._id!== action.data.data.data.content._id)
        //@ts-ignore
        beforeDeleteContents = state.contents.filter((content) => content._id!== action.data.data.data.content._id)
      }

      return {
        ...state,
        contentLoading: false,
        contents: beforeDeleteContents,
        contentsList: afterDeleteContentList,
      };

    default:
      return state;
  }
}
