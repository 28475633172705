import React, { Component } from "react";
import { signIn, tryAlreadyLoggedInProfile } from "../../redux/actions/userActions";
import {connect} from "react-redux";

class RedirectMe extends Component{
    // componentDidMount() {
    //     //@ts-ignore
    //     if(!this.props.isLoggedIn) {
    //       //@ts-ignore
    //     this.props.tryAlreadyLoggedInProfile()
    //     }
        
    // }
    render() {
        return (
            <>                           
              { //@ts-ignore
              this.props.children }
            </>
        );
    }
}

// @ts-ignore
function mapStateToProps(state) {
    return {
      isLoggedIn: state.userReducer.isLoggedIn,
    };
  }
  
  const mapDispatchToProps = (dispatch: any) => ({
    signIn: (data: any) => dispatch(signIn(data)),
    tryAlreadyLoggedInProfile: (data: any) => dispatch(tryAlreadyLoggedInProfile()),
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(RedirectMe);
  