export const chapterConstants = {
  REQUEST_COURSEBOOK_CHAPTER_ADD: "REQUEST_COURSEBOOK_CHAPTER_ADD",
  RESPONSE_COURSEBOOK_CHAPTER_ADD: "RESPONSE_COURSEBOOK_CHAPTER_ADD",
  FAILURE_COURSEBOOK_CHAPTER_ADD: "FAILURE_COURSEBOOK_CHAPTER_ADD",
  REMOVE_CHAPTER_LIST: "REMOVE_CHAPTER_LIST",
  REQUEST_COURSEBOOK_CHAPTER_FETCH: "REQUEST_COURSEBOOK_CHAPTER_FETCH",
  RESPONSE_COURSEBOOK_CHAPTER_FETCH: "RESPONSE_COURSEBOOK_CHAPTER_FETCH",
  REQUEST_GENERALBOOK_CHAPTER_ADD: "REQUEST_GENERALBOOK_CHAPTER_ADD",
  RESPONSE_GENERALBOOK_CHAPTER_ADD: "RESPONSE_GENERALBOOK_CHAPTER_ADD",
  REQUEST_GENERALBOOK_CHAPTER_FETCH: "REQUEST_GENERALBOOK_CHAPTER_FETCH",
  RESPONSE_GENERALBOOK_CHAPTER_FETCH: "RESPONSE_GENERALBOOK_CHAPTER_FETCH",
  REQUEST_CHAPTER_UPDATE: "REQUEST_CHAPTER_UPDATE",
  RESPONSE_CHAPTER_UPDATE: "RESPONSE_CHAPTER_UPDATE",
  FAILURE_CHAPTER_UPDATE: "FAILURE_CHAPTER_UPDATE",
  REQUEST_CHAPTER_DELETE: "REQUEST_CHAPTER_DELETE",
  RESPONSE_CHAPTER_DELETE: "RESPONSE_CHAPTER_DELETE",
  FAILURE_CHAPTER_DELETE: "FAILURE_CHAPTER_DELETE",
  CLEAR_MSG: "CLEAR_MSG",
  REQUEST_EPUB_DELETE: "REQUEST_EPUB_DELETE",
  RESPONSE_EPUB_DELETE: "RESPONSE_EPUB_DELETE",
  FAILURE_EPUB_DELETE: "FAILURE_EPUB_DELETE",
};
