import { combineReducers } from 'redux';

import themeReducer from './themeReducers';
import boardReducer from './boardReducers';
import educatorReducer from './educatorReducers';
import programReducer from './programReducers';
import classReducer from './classReducers';
import subjectReducer from './subjectReducers';
import bookReducer from './bookReducers';
import chapterReducer from './chapterReducers';
import contentReducer from './contentReducer';
import userReducer from './userReducer';
import videoReducer from './videoReducer';
import adminReducer from './adminReducer';


export const rootReducer = combineReducers({
	// themeReducer
	themeReducer: themeReducer,
	boardReducer: boardReducer,
	educatorReducer: educatorReducer,
	programReducer: programReducer,
	classReducer: classReducer,
	subjectReducer: subjectReducer,
	bookReducer: bookReducer,
	chapterReducer: chapterReducer,
	contentReducer: contentReducer,
	userReducer:userReducer,
	videoReducer:videoReducer,
	adminReducer:adminReducer
});

type RootReducerType = typeof rootReducer
export type AppStateType = ReturnType<RootReducerType>