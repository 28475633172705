import { programConstants } from "../constants/index";

const initialSettings = {
  program: [],
  programs: [],
  programsList: [],
  programLoading: false,
  msg: null,
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action) {
  switch (action.type) {
    case programConstants.REQUEST_PROGRAM_ADD:
      return {
        ...state,
        programLoading: true,
      };

    case programConstants.RESPONSE_PROGRAM_ADD:
      console.log("action is===", action);
      console.log("Action.data", action.data);
      const program2 = action.data && action.data.data ? action.data.data : null;
      console.log("program", program2);

      return {
        ...state,
        programLoading: false,
        programs: [...state.programs,...action.data.data.program],
        program: program2,
        msg: action.data.data.msg,
      };

    case programConstants.FAILURE_PROGRAM_ADD:
      return {
        ...state,
        programLoading: false,
        msg: action.data.data.msg,
      };

    case programConstants.REMOVE_PROGRAM_LIST:
      return {
        ...state,
        programsList: [],
        programs: [],
      };

    case programConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    // Fetch program Reducers
    case programConstants.REQUEST_PROGRAM_FETCH:
      return {
        ...state,
        programLoading: true,
      };

    case programConstants.RESPONSE_PROGRAM_FETCH:
      console.log("Action Data", action.data);
      const programList =
        action.data &&
        action.data.data &&
        action.data.data.program &&
        action.data.data.program.length
          ? action.data.data.program
          : [];
      const counts = action.data && action.data.counts ? action.data.counts : [];
      console.log("programList", programList);
      return {
        ...state,
        programLoading: false,
        programsList: programList,
        count: counts,
      };

    // Reducer for update Program
    case programConstants.REQUEST_PROGRAM_UPDATE:
      return {
        ...state,
        programLoading: true,
      };
    case programConstants.RESPONSE_PROGRAM_UPDATE:
      const updateProgramMeta = action.data;
      console.log("Action data>>", updateProgramMeta);

      console.log("Update Program id", action.data.data.program._id);
      let beforeEditProgramList = state.programsList;
      let afterEditProgramList: any[] = [];

      beforeEditProgramList.map((pros) => {
        console.log("id", action.data.data.program._id);
        //@ts-ignore
        if (pros._id !== action.data.data.program._id) {
          afterEditProgramList.push(pros);
        } else {
          afterEditProgramList.push(action.data.data.program);
        }
        return console.log(pros);
      });

      return {
        ...state,
        programLoading: false,
        programsList: afterEditProgramList,
        program: updateProgramMeta,
        msg: action.data.data.msg,
      };

    // Reducer for delete program
    case programConstants.REQUEST_PROGRAM_DELETE:
      return {
        ...state,
        programLoading: true,
      };
    case programConstants.RESPONSE_PROGRAM_DELETE:
      const deleteProgramMeta = action.data;
      console.log("Action data", deleteProgramMeta);
      console.log("Action", action);
      console.log("Delete Program id", action.data.data.data.program._id);
      let beforeDeleteProgramList = state.programsList;
      let beforeDeletePrograms = state.programs;
      //@ts-ignore
      console.log("programlist", beforeDeleteProgramList);
      let afterDeleteProgramList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.program._id
      ) {
       //@ts-ignore
       beforeDeleteProgramList = state.programsList.filter((program) => program._id!== action.data.data.data.program._id)
       //@ts-ignore
       beforeDeletePrograms = state.programs.filter((program) => program._id!== action.data.data.data.program._id)
      }

      return {
        ...state,
        programLoading: false,
        programs: beforeDeletePrograms,
        programsList: afterDeleteProgramList,
      };

    default:
      return state;
  }
}
