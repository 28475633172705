import { videoConstants } from "../constants/index";

const initialSettings = {
  video: [],
  videos: [],
  videosList: [],
  videoLoading: false,
  msg: null,
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action) {
    switch (action.type) {
  
      case videoConstants.REQUEST_VIDEO_ADD:
        return {
          ...state,
          videoLoading: true,
        }
  
      case videoConstants.RESPONSE_VIDEO_ADD:
        
        console.log("action is===",action);
        return {
          ...state,
          videoLoading: false,
          videos: [...state.videos,action.data.data.video], //'create video' response stored in videos  
          msg: action.data.data.msg
        }
  
        case videoConstants.FAILURE_VIDEO_ADD:
          return {
            ...state,
            videoLoading: false,
            msg: action.data.data.msg,
          };

      case videoConstants.CLEAR_MSG:
        return {
            ...state,
            msg: "",
        }
  
      case videoConstants.REMOVE_VIDEO_LIST:
          return {
            ...state,
            videosList: [],
            videos: []
          }
        // Fetch video Reducers
      case videoConstants.REQUEST_VIDEO_FETCH:
        return {
          ...state,
          videoLoading: true,
        }
  
      case videoConstants.RESPONSE_VIDEO_FETCH:
        console.log("==Action.data==", action.data)
        const videoList = action.data && action.data.data && action.data.data.video ? action.data.data.video : []
        const counts = action.data && action.data.counts ? action.data.counts : []
        console.log("videoList====>", videoList)
        return {
          ...state,
          videoLoading: false,
          videosList: videoList,
          count:counts
        }
        
        // Reducer for update Video
      case videoConstants.REQUEST_VIDEO_UPDATE:
        return {
              ...state,
              videoLoading: true,
          }
      case videoConstants.RESPONSE_VIDEO_UPDATE:
      const updateVideoMeta = action.data 
      console.log("Action data>",updateVideoMeta)
  
      console.log("Update Video id", action.data.data.video._id)
      let beforeEditVideoList = state.videosList
      let afterEditVideoList: any[] = [];

      beforeEditVideoList.map((vdo) => {
        console.log("id", action.data.data.video._id);
        //@ts-ignore
        if (vdo._id !== action.data.data.video._id) {
          afterEditVideoList.push(vdo);
        } else {
          afterEditVideoList.push(action.data.data.video);
        }
        return console.log(vdo);
      });

      return {
        ...state,
        videoLoading: false,
        videosList: afterEditVideoList,
        video: updateVideoMeta,
        msg: action.data.data.msg,
      };

    // Reducer for delete video
    case videoConstants.REQUEST_VIDEO_DELETE:
      return {
        ...state,
        videoLoading: true,
      };
    case videoConstants.RESPONSE_VIDEO_DELETE:
      const deleteVideoMeta = action.data;
      console.log("Action data", deleteVideoMeta);
      console.log("Action", action);
      console.log("Delete Video id", action.data.data.data.video._id);
      let beforeDeleteVideoList = state.videosList; //videos list includes all video entries
      let beforeDeleteVideos = state.videos;
      //@ts-ignore
      console.log("videolist", beforeDeleteVideoList);
      let afterDeleteVideoList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.video._id
      ) {
       //@ts-ignore
       beforeDeleteVideoList = state.videosList.filter((video) => video._id!== action.data.data.data.video._id)
       //@ts-ignore
       beforeDeleteVideos = state.videos.filter((video) => video._id!== action.data.data.data.video._id)
      }

      return {
        ...state,
        videoLoading: false,
        videos: beforeDeleteVideos, 
        videosList: afterDeleteVideoList,
      };

    default:
      return state;
  }
}
