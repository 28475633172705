const APPCONFIG = {
    // local
    //baseAPIUrl: "//localhost:5000",
  
    // live
    baseAPIUrl: "https://shiksha.ntb.one",
    // baseAPIUrl: window.location && window.location.origin ? window.location.origin : 'https://celebs.ntb.one',
    brand: "NTB EDULITE",
    AutoCloseMobileNav: true, // Boolean: true, false. Automatically close sidenav on route change (Mobile only)
    color: {
      primary: "#1890ff",
      success: "#66BB6A",
      info: "#01BCD4",
      infoAlt: "#948aec",
      warning: "#ffc53d",
      danger: "#ff4d4f",
      text: "#3D4051",
      gray: "#EDF0F1",
    },
    MQTTConfig: {
      MQTT_BROKER_HOST: "broker.thdash.uniolabs.com",
      MQTT_BROKER_PORT: 9001,
      MQTT_USERNAME: "master98",
      MQTT_PASSWORD: "tIDVIlvfBR",
    },
  }
  
  export default APPCONFIG
  