import React from "react";
import { BrowserRouter as Router, Route, Switch, RouteComponentProps } from "react-router-dom";
import { dashboardLayoutRoutes } from "./index";
import { authLayoutRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
// import SignIn from "../pages/auth/SignIn";
// import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import RedirectMe from "../pages/auth/RedirectMe";
import { RouteInfoType } from "../types/types";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteInfoType>) =>
  routes.map(({component: Component, children, path}, index: number) =>{
      return children ? (
      children.map((element, index: number) => (
        <Route
          key={index}
          path={element.path}
          exact
          render={(props: RouteComponentProps) => (
            <Layout>
              <element.component {...props} />
            </Layout>
          )}
        />
      ))
    ) : Component ? (
      <Route
        key={index}
        path={path}
        exact
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : null
  });

const Routes = () => (
  <Router>
    <Switch>
      {childRoutes(DashboardLayout, dashboardLayoutRoutes)}
      {childRoutes(AuthLayout, authLayoutRoutes)}
      {/* @ts-ignore */}
      <RedirectMe>
      <Route
        render={() => (
          <AuthLayout>
            {/* <SignIn />
            <ResetPassword /> */}
            <Page404 />
          </AuthLayout>
        )}
      />
      </RedirectMe>
    </Switch>
  </Router>
);

export default Routes;