import AppConfig from "../constants/AppConfig";
import axios from "axios";
import { userConstants } from "../constants/index";

export const userActions = {
  addUser,
  fetchUsersList,
  updateUser,
  deleteUser,
  signIn,
  tryAlreadyLoggedInProfile,
  loadProfileFromLocalStore,
  saveProfileToLocalStore,
  logout,
  deleteProfileFromLocalStore,
  clearMessage,
  uploadUsers,
};

// Add User
// @ts-ignore
export function addUser(data, token) {
  console.log("Add users params", data);
  // @ts-ignore
  return (dispatch) => {
    dispatch({ type: userConstants.REQUEST_USER_ADD, data: {} });
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    };
    console.log("params", data);
    axios
      .post(AppConfig.baseAPIUrl + "/api/auth/signup", data, config)
      .then((result) => {
        console.log("Result is", result);
        const data = result.data && result.data.data ? result.data.data : {};
        dispatch({ type: userConstants.RESPONSE_USER_ADD, data: { error: false, data } });
      })
      .catch((error) => {
        let data = { error: true };
        try {
          //@ts-ignore
          data["errors"] = error.response.data.errors;
          console.log("Error response: ", error.response);
          console.log("Error response body: ", error.response.body);
        } catch (ex) {
          // @ts-ignore
          data["errors"] = [
            {
              param: "Network Error",
              msg: "Unable to connect. Please ensure your network is connected properly.",
            },
          ];
        }
        dispatch({ type: userConstants.FAILURE_USER_ADD, data: data });
      });
  };
}

// Fetch all users list
// @ts-ignore
export function fetchUsersList(payload, token) {
  console.log("params token is...", token);
  // @ts-ignore
  return (dispatch) => {
    dispatch({ type: userConstants.REQUEST_USER_FETCH, data: {} });
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
      params: payload
    };
    axios
      .get(AppConfig.baseAPIUrl + "/api/user/get/list", config)
      .then((result) => {
        const data = result.data && result.data.data ? result.data.data : {};
        const counts = result.data && result.data.count ? result.data.count : 0;
        console.log("userActions: fetch: fetch board result success: ", data);
        dispatch({ type: userConstants.RESPONSE_USER_FETCH, data: { error: false, data, counts } });
        return data;
      })
      .catch((error) => {
        let data = { error: true };
        try {
          // @ts-ignore
          data["errors"] = error.response.data.errors;
        } catch (ex) {
          // @ts-ignore
          data["errors"] = [
            {
              param: "Network Error",
              msg: "Unable to connect. Please ensure your network is connected properly.",
            },
          ];
        }
        dispatch({ type: userConstants.FAILURE_USER_FETCH, data: {} });
        return data;
      });
  };
}

// Update User
//@ts-ignore
export function updateUser(data, token) {
  console.log("Update user params", data);
  //@ts-ignore
  return (dispatch) => {
    dispatch({ type: userConstants.REQUEST_USER_UPDATE, data: {} });
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    };
    console.log("params", data);
    axios
      .post(AppConfig.baseAPIUrl + "/api/user/update", data, config)
      .then((result) => {
        console.log("Result is", result);
        const data = result.data && result.data.data ? result.data.data : {};
        dispatch({ type: userConstants.RESPONSE_USER_UPDATE, data: { error: false, data } });
        console.log("updated Users", data);
      })
      .catch((error) => {
        let data = { error: true };
        try {
          //@ts-ignore
          data["errors"] = error.response.data.errors;
        } catch (ex) {
          //@ts-ignore
          data["errors"] = [
            {
              param: "Network Error",
              msg: "Unable to connect. Please ensure your network is connected properly.",
            },
          ];
        }
        dispatch({ type: userConstants.FAILURE_USER_UPDATE, data: data });
      });
  };
}

// Delete Users
//@ts-ignore
export function deleteUser(data, token) {
  console.log("data", data);
  //@ts-ignore
  return (dispatch) => {
    dispatch({ type: userConstants.REQUEST_USER_DELETE, data: {} });
    const config = {
      headers: {
        "content-Type": "application/json",
        Authorization: token,
      },
    };
    //@ts-ignore
    //  console.log("user id",data.id,data.educatorId);
    axios
      .post(
        //@ts-ignore
        AppConfig.baseAPIUrl + "/api/user/delete",
        data,
        config
      )
      .then((result) => {
        console.log("result", result.data);
        const data = result.data && result.data ? result.data : {};
        dispatch({ type: userConstants.RESPONSE_USER_DELETE, data: { error: false, data } });
        console.log("deleted Users ", data);
      })
      .catch((error) => {
        let data = { error: true };
        try {
          //@ts-ignore
          data["errors"] = error.response.data.errors;
        } catch (ex) {
          //@ts-ignore
          data["errors"] = [
            {
              param: "Network Error",
              msg: "Unable to connect. Please ensure your network is connected properly.",
            },
          ];
        }
        dispatch({ type: userConstants.FAILURE_USER_DELETE, data: data });
      });
  };
}

// @ts-ignore
export function signIn(params) {
  console.log("Add users params", params);
  // @ts-ignore
  return (dispatch) => {
    dispatch({ type: userConstants.REQUEST_USER_SIGNIN, data: {} });
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    console.log("params", params);
    axios
      .post(AppConfig.baseAPIUrl + "/api/auth/signin", params, config)
      .then((result) => {
        console.log("Result is", result);
        const response = result.data && result.data.data ? result.data.data : {};
        console.log("data2", response);
        if (params.rememberMe) {
          saveProfileToLocalStore(response);
        }
        dispatch({ type: userConstants.RESPONSE_USER_SIGNIN, data: { error: false, response } });
      })
      .catch((error) => {
        let data = { error: true };
        try {
          //@ts-ignore
          data["errors"] = error.response.data.errors;
          let new_data =
            error &&
            error.response &&
            error.response.data &&
            error.response.data.errors &&
            error.response.data.errors[0] &&
            error.response.data.errors[0].msg;
          dispatch({ type: userConstants.WRONG_USER_SIGNIN_CREDENTIALS, data: new_data });
        } catch (ex) {
          // @ts-ignore
          data["errors"] = [
            {
              param: "Network Error",
              msg: "Unable to connect. Please ensure your network is connected properly.",
            },
          ];
        }
        dispatch({ type: userConstants.FAILURE_USER_SIGNIN, data: data });
      });
  };
}

export function tryAlreadyLoggedInProfile() {
  console.log("trying already logIn");
  //@ts-ignore
  return (dispatch) => {
    //@ts-ignore
    let data = loadProfileFromLocalStore();
    if (data && data.length) {
      try {
        data = JSON.parse(atob(data));
        return dispatch({
          type: userConstants.RESPONSE_USER_SIGNIN,
          data: { error: false, data: data },
        });
      } catch (ex) {
        console.log("Error loading profile: ", ex, data);
      }
    }
  };
}

function loadProfileFromLocalStore() {
  //const authToken = localStorage.getItem("thtt");
  const data = localStorage.getItem("thup");
  return data;
}

//@ts-ignore
function saveProfileToLocalStore(data) {
  localStorage.setItem("thup", btoa(JSON.stringify(data)));
  //localStorage.setItem("thtt", btoa(data.token));
}

export function logout() {
  //@ts-ignore
  return (dispatch) => {
    deleteProfileFromLocalStore();
    return dispatch({ type: userConstants.USER_DO_LOGOUT, data: { error: false, data: {} } });
  };
}

function deleteProfileFromLocalStore() {
  return localStorage.removeItem("thup");
}

// @ts-ignore
export function clearMessage() {
  //@ts-ignore
  return (dispatch) => {
    dispatch({ type: userConstants.CLEAR_MSG });
  };
}

//upload multiple users using csv
//@ts-ignore
export function uploadUsers(data, token) {
  console.log("Add users params", data);
  // @ts-ignore
  return (dispatch) => {
    dispatch({ type: userConstants.REQUEST_MULTIPLE_USER_ADD, data: {} });
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: token,
      },
    };
    console.log("params", data);
    axios
      .post(AppConfig.baseAPIUrl + "/api/user/upload", { users: data }, config)
      .then((result) => {
        console.log("Result is", result);
        const data = result.data && result.data.data ? result.data.data : {};
        dispatch({ type: userConstants.RESPONSE_MULTIPLE_USER_ADD, data: { error: false, data } });
      })
      .catch((error) => {
        let data = { error: true };
        try {
          //@ts-ignore
          data["errors"] = error.response.data.errors;
          console.log("Error response: ", error.response);
          console.log("Error response body: ", error.response.body);
        } catch (ex) {
          // @ts-ignore
          data["errors"] = [
            {
              param: "Network Error",
              msg: "Unable to connect. Please ensure your network is connected properly.",
            },
          ];
        }
        dispatch({ type: userConstants.FAILURE_MULTIPLE_USER_ADD, data: data });
      });
  };
}
