export const classConstants = {
  REQUEST_CLASS_ADD: "REQUEST_CLASS_ADD",
  RESPONSE_CLASS_ADD: "RESPONSE_CLASS_ADD",
  FAILURE_CLASS_ADD: "FAILURE_CLASS_ADD",
  REMOVE_CLASS_LIST: "REMOVE_CLASS_LIST",
  REQUEST_CLASS_FETCH: "REQUEST_CLASS_FETCH",
  RESPONSE_CLASS_FETCH: "RESPONSE_CLASS_FETCH",
  FAILURE_CLASS_FETCH: "FAILURE_CLASS_FETCH",
  REQUEST_CLASS_UPDATE: "REQUEST_CLASS_UPDATE",
  RESPONSE_CLASS_UPDATE: "RESPONSE_CLASS_UPDATE",
  FAILURE_CLASS_UPDATE: "FAILURE_CLASS_UPDATE",
  REQUEST_CLASS_DELETE: "REQUEST_CLASS_DELETE",
  RESPONSE_CLASS_DELETE: "RESPONSE_CLASS_DELETE",
  FAILURE_CLASS_DELETE: "FAILURE_CLASS_DELETE",
  CLEAR_MSG: "CLEAR_MSG",
  RESPONSE_CLASS_DELETE_ADD: "RESPONSE_CLASS_DELETE_ADD",
  REQUEST_CLASS_DELETE_ADD: "REQUEST_CLASS_DELETE_ADD",
};
