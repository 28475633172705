import { educatorConstants } from "../constants/index";

const initialSettings = {
  educator: [],
  educators: [],
  educatorsList: [],
  educatorsLoading: false,
  msg: null,
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action) {
  switch (action.type) {
    case educatorConstants.REQUEST_EDUCATOR_ADD:
      return {
        ...state,
        educatorsLoading: true,
      };

    case educatorConstants.RESPONSE_EDUCATOR_ADD:
      console.log("action is===", action);
      console.log("Action.data", action.data);
      const educator2 =
        action.data && action.data.data && action.data.data.educator
          ? action.data.data.educator
          : [];
      console.log("educator2", educator2);

      return {
        ...state,
        educatorsLoading: false,
        educators: [...state.educators,action.data.data.educator],
        educator: educator2,
        msg: action.data.data.msg,
      };

    case educatorConstants.FAILURE_EDUCATOR_ADD:
      return {
        ...state,
        educatorsLoading: false,
        msg: action.data.data.msg,
      };

    case educatorConstants.REMOVE_EDUCATOR_LIST:
      return {
        ...state,
        educatorsList: [],
        educators: [],
      };

    case educatorConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    // Fetch educator Reducers
    case educatorConstants.REQUEST_EDUCATOR_FETCH:
      return {
        ...state,
        educatorsLoading: true,
      };

    case educatorConstants.RESPONSE_EDUCATOR_FETCH:
      console.log("Action.data", action.data);
      const educatorList =
        action.data &&
        action.data.data &&
        action.data.data.educator &&
        action.data.data.educator.length
          ? action.data.data.educator
          : [];
      const counts = action.data && action.data.counts ? action.data.counts : [];
      console.log("educatorList", educatorList);
      return {
        ...state,
        educatorsLoading: false,
        educatorsList: educatorList,
        count: counts,
      };

    // Reducer for update Educator
    case educatorConstants.REQUEST_EDUCATOR_UPDATE:
      return {
        ...state,
        educatorsLoading: true,
      };
    case educatorConstants.RESPONSE_EDUCATOR_UPDATE:
      const updateEducatorMeta = action.data;
      console.log("Action data>", updateEducatorMeta);

      console.log("Update Educator id", action.data.data.educator._id);
      let beforeEditEducatorList = state.educatorsList;
      let afterEditEducatorList: any[] = [];

      beforeEditEducatorList.map((educator) => {
        console.log("id", action.data.data.educator._id);
        //@ts-ignore
        if (educator._id !== action.data.data.educator._id) {
          afterEditEducatorList.push(educator);
        } else {
          afterEditEducatorList.push(action.data.data.educator);
        }
        return console.log(educator);
      });

      return {
        ...state,
        educatorsLoading: false,
        educatorsList: afterEditEducatorList,
        educator: updateEducatorMeta,
        msg: action.data.data.msg,
      };

    // Reducer for delete Educator
    case educatorConstants.REQUEST_EDUCATOR_DELETE:
      return {
        ...state,
        educatorsLoading: true,
      };
    case educatorConstants.RESPONSE_EDUCATOR_DELETE:
      const deleteEducatorMeta = action.data;
      console.log("Action data", deleteEducatorMeta);
      console.log("Action", action);
      console.log("Delete Educator id", action.data.data.data.educator._id);
      let beforeDeleteEducatorList = state.educatorsList;
      let beforeDeleteEducators = state.educators;
      //@ts-ignore
      console.log("educatorlist", beforeDeleteEducatorList);
      let afterDeleteEducatorList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.educator._id
      ) {
        //@ts-ignore
        beforeDeleteEducatorList = state.educatorsList.filter((educator) => educator._id!== action.data.data.data.educator._id)
        //@ts-ignore
        beforeDeleteEducators = state.educators.filter((educator) => educator._id!== action.data.data.data.educator._id)
      }

      return {
        ...state,
        educatorsLoading: false,
        educators: beforeDeleteEducators,
        educatorsList: afterDeleteEducatorList,
      };

    default:
      return state;
  }
}
