import { subjectConstants } from "../constants/index";

const initialSettings = {
  subject: [],
  subjects: [],
  subjectsList: [],
  subjectsLoading: false,
  msg: null,
  count: 0,
};

// @ts-ignore
export default function reducer(state = initialSettings, action) {
  switch (action.type) {
    case subjectConstants.REQUEST_SUBJECT_ADD:
      return {
        ...state,
        subjectsLoading: true,
      };

    case subjectConstants.RESPONSE_SUBJECT_ADD:
      console.log("Action.data", action.data);

      console.log("action is===", action);
      return {
        ...state,
        subjectsLoading: false,
        subjects: [...state.subjects, ...action.data.data.subject],
        msg: action.data.data.msg,
      };

    case subjectConstants.FAILURE_SUBJECT_ADD:
      return {
        ...state,
        subjectsLoading: false,
        msg: action.data.data.msg,
      };

    case subjectConstants.CLEAR_MSG:
      return {
        ...state,
        msg: "",
      };

    case subjectConstants.REMOVE_SUBJECT_LIST:
      return {
        ...state,
        subjectsList: [],
        subjects: [],
      };
    // Fetch subject Reducers
    case subjectConstants.REQUEST_SUBJECT_FETCH:
      return {
        ...state,
        subjectsList: [],
        subjectsLoading: true,
      };

    case subjectConstants.RESPONSE_SUBJECT_FETCH:
      console.log("Action.data", action.data);
      const subjectList =
        action.data && action.data.data && action.data.data.subject ? action.data.data.subject : [];
      const counts = action.data && action.data.counts ? action.data.counts : [];
      console.log("subjectList", subjectList);
      return {
        ...state,
        subjectsLoading: false,
        subjectsList: subjectList,
        count: counts,
      };

    // Reducer for update Subject
    case subjectConstants.REQUEST_SUBJECT_UPDATE:
      return {
        ...state,
        subjectsLoading: true,
      };
    case subjectConstants.RESPONSE_SUBJECT_UPDATE:
      const updateSubjectMeta = action.data;
      console.log("Action data", updateSubjectMeta);

      console.log("Update Subject id", action.data.data.subject._id);
      let beforeEditSubjectList = state.subjectsList;
      let afterEditSubjectList: any[] = [];

      beforeEditSubjectList.map((sub) => {
        console.log("id", action.data.data.subject._id);
        //@ts-ignore
        if (sub._id !== action.data.data.subject._id) {
          afterEditSubjectList.push(sub);
        } else {
          afterEditSubjectList.push(action.data.data.subject);
        }
        return console.log(sub);
      });

      return {
        ...state,
        subjectsLoading: false,
        subjectsList: afterEditSubjectList,
        subject: updateSubjectMeta,
        msg: action.data.data.msg,
      };

    // Reducer for delete Subject
    case subjectConstants.REQUEST_SUBJECT_DELETE:
      return {
        ...state,
        subjectsLoading: true,
      };
    case subjectConstants.RESPONSE_SUBJECT_DELETE:
      const deleteSubjectMeta = action.data;
      console.log("Action data", deleteSubjectMeta);
      console.log("Action", action);
      console.log("Delete Subject id", action.data.data.data.subject._id);
      let beforeDeleteSubjectList = state.subjectsList;
      let beforeDeleteSubjects = state.subjects;
      //@ts-ignore
      console.log("subjectlist", beforeDeleteSubjectList);
      let afterDeleteSubjectList: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.subject._id
      ) {
        //@ts-ignore
        beforeDeleteSubjectList = state.subjectsList.filter((subject) => subject._id!== action.data.data.data.subject._id)
        //@ts-ignore
        beforeDeleteSubjects = state.subjects.filter((subject) => subject._id!== action.data.data.data.subject._id)
      }

      return {
        ...state,
        subjectsLoading: false,
        subjects: beforeDeleteSubjects,
        subjectsList: afterDeleteSubjectList,
      };

    case subjectConstants.RESPONSE_SUBJECT_DELETE_ADD:
      const deleteSubjectMeta2 = action.data;
      console.log("Action data", deleteSubjectMeta2);
      console.log("Action", action);
      console.log("Delete Subject id", action.data.data.data.subjects._id);
      let beforeDeleteSubjectList2 = state.subjects;
      //@ts-ignore
      console.log("subjectlist2", beforeDeleteSubjectList2);
      let afterDeleteSubjectList2: any[] = [];
      if (
        action &&
        action.data &&
        action.data.data &&
        action.data.data.data &&
        action.data.data.data.subjects._id
      ) {
        if (beforeDeleteSubjectList2.length) {
          beforeDeleteSubjectList2.map((sub: any[]) => {
            console.log("subject", sub);
            //@ts-ignore
            if (sub._id !== action.data.data.data.subjects._id) {
              afterDeleteSubjectList2.push(sub);
            }
            return console.log(sub);
          });
        }
      }

      return {
        ...state,
        subjectsLoading: false,
        subjects: afterDeleteSubjectList2,
      };

    default:
      return state;
  }
}
